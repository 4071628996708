<script setup>
const props = defineProps({
  name: "",
  error: "",
  label: "",
});

function changeLabel(error) {
  // The code below is catching about 90% of the cases,
  // however, if we need more control, we can pass in an
  // array, for example ["my.locale.key", {foo: 42, bar: 99}
  // added appErrors.failedBackendCall for edge case, where
  // agw-manager-service could not make a backend call.
  if (error.endsWith("unknownMailServer")) {
    return "form.errors.invalidEmail";
  } else if (error.endsWith("InvalidEmail")) {
    return "form.errors.invalidEmail";
  } else if (error.includes('backend call')) {
    return "appErrors.failedBackendCall";
  } else if (error.includes("special characters")) {
    return "form.errors.specialCharacters";
  } else if (error.includes("duplicateMailError")) {
    return "identityForm.errors.duplicateMailError";
  } else if (error.endsWith("telNrSwitzerland")) {
    return "form.errors.invalidTelNrSwitzerland"
  } else if (error.endsWith("telNrLiechtenstein")) {
    return "form.errors.invalidTelNrLiechtenstein"
  } else if (error.endsWith("telNrOther")) {
    return "form.errors.invalidTelNrOther"
  }
  return null;
}
</script>

<template>
  <small :id="name" class="form-text invalid-feedback" style="display: block">
    <span v-if="error && Array.isArray(error)">
      {{ $t(...error) }}
    </span>
    <!-- "Ack" checkboxes do not have a label. -->
    <span v-else-if="error && changeLabel(error) === null && !label">
      {{ $t("form.errors.checkRegulations") }}
    </span>
    <!-- "Catch all" for errors without special label (see "changeLabel" function, above -->
    <span v-else-if="error && changeLabel(error) === null">
      {{ $t("form.errors.missingProperty", { label: label }) }}
    </span>
    <!-- Special cases, see above -->
    <span v-else-if="error && changeLabel(error) !== null">
      {{ $t(changeLabel(error)) }}
    </span>
  </small>
</template>

<style scoped>
small {
  line-height: 0.5;
}

span {
  position: absolute;
  z-index: 1;
  padding-bottom: 20;
}
</style>
