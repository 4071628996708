<script setup>
import Autocomplete           from "@/components/form/Autocomplete.vue";
import AddFormButton          from "@/components/AddFormButton.vue";
import InfoBox                from "@/components/InfoBox.vue";
import InfoWithLabel          from "@/components/form/InfoWithLabel.vue";
import InputCheckbox          from "@/components/form/InputCheckbox.vue";
import DatePicker             from "@/components/form/DatePicker.vue";
import InputTel               from "@/components/form/InputTel.vue";
import InputText              from "@/components/form/InputText.vue";
import InputTextPopoverGln    from "@/components/form/InputTextPopoverGln.vue";

import { useAgwManagerService } from "@/services/AgwManagerService";
import {setGlnError} from "@/utils/gln.js";

const props = defineProps({
  displayAssociation: false,
  errors: Object,
  inputs: Object,
  info: Object,
  inputOptions: Object,
  professionsWithTitle: Object,
  language: "",
  validators: Object,
});

const addForm = () => {
  props.inputs.professions.push("");
};

const agwManagerService = useAgwManagerService();

const registerMap = {
  medreg: "MedReg",
  psyreg: "PsyReg",
  nareg: "NaReg",
  gesreg: "GesReg",
  refdata: "RefData",
};

// Look up GLN and if there is a match,
// prefill some fields by setting store values (via inputs).
function checkGln(event) {
  const gln = event.target.value;
  props.info.gln = null;
  setGlnError(gln, props.errors, "gln");
  // Immediate check, without waiting for "blur"
  if (gln?.length === 13 && !props.errors.gln) {
    lookupGln(event);
    return;
  }
  _clearInfo();
}
async function lookupGln(event) {
  const gln = event.target.value;
  if (!gln || gln.length !== 13) {
    _clearInfo();
    return;
  }

  // Entering GLN is error prone. Try to be helpful.
  setGlnError(gln, props.errors, "gln");
  if (props.errors.gln) return;

  props.info.gln = ["furtherInformation.glnLookupPending"];
  props.errors.gln = null;

  const data = await agwManagerService.fetchJson(
    `/productfinder/gln/${gln}?type=NAT`,
    {
      method: "GET",
      headers: {
        'Content-Type': 'application/json'
      },
    }
  );
  if (data?.result) {
    _handleGlnResult(data.result);
    return;
  }
  props.info.gln = null;
  if (data?.errors[0].message === 'No match'){
    props.errors.gln = ['form.glnErrors.noMatchPerson'];
  }
  else {
    props.errors.gln = ['form.glnErrors.unknownError'];
  }
}

function _clearInfo() {
  const info = props.info;
  info.gln = null;
  info.salutation = null;
  for (const key of ["professions", "profTitles"]) {
    if (info[key]) {
      for (let i = 0; i < info[key].length; i++) {
        info[key][i] = null;
      }
    }
  }
}

function _handleGlnResult(result) {
    // If we get a response we know GLN was found in RefData.
    props.info.gln = [
      "register.infoFoundInRegister",
      { register: registerMap.refdata }
    ];
    const alreadySet = {profNum: 0};
    for (const register of ['medreg', 'psyreg', 'refdata']) {
      if (! result[register]) {
        continue;
      }
      // If found in MedReg, and also PsyReg etc, do not overwrite.
      if (!alreadySet.salutation && result[register].gender) {
        props.inputs.salutation = result[register].gender;
        if (props.inputs.salutation) {
          props.info.salutation = [
            "register.infoFoundInRegister",
            { register: registerMap[register] }
          ];
        }
        alreadySet.salutation = true;
      }

      if (!result[register]) continue;
      _updateJobsTitles(result, register, alreadySet)

    }
    props.errors.gln = "";
}

// Merge professions and titles.
// The data source reports them as separate arrays.
// So, for example, assume that the first title in medreg
// belongs to the first profession in medreg etc.
function _updateJobsTitles(result, register, alreadySet) {
  const profNum = alreadySet.profNum;
  for (const profession of result[register].professions) {
    props.inputs.professions[profNum] = profession;
    props.inputs.profTitles[profNum] = result[register]?.titles[profNum] || null;
    props.info.professions[profNum] = [
      "register.infoFoundInRegister",
      { register: registerMap[register] }
    ];
    if (props.inputs.profTitles[profNum]) {
      props.info.profTitles[profNum] = [
        "register.infoFoundInRegister",
        { register: registerMap[register] }
      ];
    }
    alreadySet[profNum]++;
  }
}

// if a profession changes, make an adjacent "profession title" input
// visible, depending on profession.
// And call "regular" validator.
// "validate" gets executed on value change, arguably an unfortunate
// name.
const profNameChange = (event, options, index) => {
  if (props.inputs.professions[index] in props.professionsWithTitle) {
    props.inputs.profTitles[index] = "";
  } else {
    props.inputs.profTitles[index] = undefined;
  }
  props.validators.fromOptions(event, options);
};

const profTitleNameChange = (event, options, index) => {
  props.validators.fromOptions(event, options);
}

const removeForm = (topic, index) => {
  props.inputs[topic].splice(index, 1);
};

</script>

<template>
  <form data-test="for-pers" autocomplete="off" novalidate>
    <div style="display: flex">
      <div
        style="flex: 1"
        class="col-lg-6 col-md-6 col-sm-1 col-1 max-width"
      >
        <InputTextPopoverGln
          name="gln"
          :label="$t('furtherInformation.person.gln')"
          :info="info.gln"
          minLength="0"
          maxLength="13"
          :titlePopover="$t('form.glnTitle')"
          :urlText="$t('form.glnUrlText')"
          keypath="form.glnText"
          v-model="inputs.gln"
          :error="errors.gln"
          @input="(event) => checkGln(event)"
        />
        <!-- The logic of enabling / disabling select is:
             "if info is set, disable me."
             (Because info is only set if we find value in register,
             in which case we want to make select read only.) -->
        <div v-for="(form, index) in inputs.professions">
          <Autocomplete
            :showPleaseSelect="true"
            :name="'professions'+index"
            :label="$t('form.profession')"
            :error="errors.professions[index]"
            :language
            :suggestions="inputOptions.professions"
            v-model="inputs.professions[index]"
            @done="(key, suggestions) => profNameChange(
              {target: {name: 'professions'+index, value: key}}, suggestions, index)"
            @close="removeForm('professions', index)"
            :showRemove="index > 0"
            :index="index"
            :inputs="inputs"
            class="required"
          />


          <Autocomplete
            v-show="professionsWithTitle[inputs.professions[index]]"
            :showPleaseSelect="true"
            :disabled="info.profTitles[index] ? true : false"
            :name="'profTitles'+index"
            :label="$t('form.profTitle')"
            :error="errors.profTitles[index]"
            :info="info.profTitles[index]"
            :language
            :suggestions="inputOptions.profTitles"
            v-model="inputs.profTitles[index]"
            @done="(key, suggestions) => profTitleNameChange(
              {target: {name: 'profTitles'+index, value: key}}, suggestions, index)"
          />
        </div>
        <AddFormButton
          tabindex="0"
          class="max-width"
          :linkText="$t('furtherInformation.person.addJobName')"
          formName="professions"
          @handleClick="() => addForm()"
          v-if="inputs.professions.length < 2"
        />
        <InfoWithLabel
          v-if="displayAssociation"
          :label="$t('form.association')"
          :value="inputs.association"
        />
        <InputText
          v-if="displayAssociation"
          name="associationMemberNumber"
          :label="$t('form.associationMemberNumber')"
          v-model="inputs.associationMemberNumber"
          :error="errors.associationMemberNumber"
          @validate="(event) => validators.required(event)"
        />
        <Autocomplete
          name="salutation"
          :label="$t('form.salutation')"
          :language
          required="required"
          :suggestions="inputOptions.salutations"
          v-model="inputs.salutation"
          :error="errors.salutation"
          :info="info.salutation"
          :disabled="info.salutation ? true : false"
          @validate="(event) => validators.autocomplete(event, inputOptions.salutations)"
        />
        <Autocomplete
          :showPleaseSelect="true"
          name="salTitle"
          :label="$t('form.salTitle')"
          :language
          :suggestions="inputOptions.salTitles"
          v-model="inputs.saltitle"
          :error="errors.saltitle"
          @validate="(event) => validators.autocomplete(event, inputOptions.salTitles)"
        />
        <InputTel
          namePhoneCountry="phoneCountry"
          namePhone="phone"
          :label="$t('form.mobilePhone')"
          :language
          :optionsCountry="inputOptions.telCountries"
          topic="countries"
          required="required"
          v-model:selectedPhoneCountry="inputs.phoneCountry"
          v-model:valuePhone="inputs.phone"
          :errorPhoneCountry="errors.phoneCountry"
          :errorPhone="errors.phone"
          @validatePhoneCountry="(event, options) => validators.autocomplete(
            event, inputOptions.countries)"
          @validatePhone="(event) => validators.phone(event, inputs.phoneCountry)"
        />
        <Autocomplete
          name="language"
          :label="$t('form.correspondenceLanguage')"
          :language
          required="required"
          :suggestions="inputOptions.languages"
          v-model="inputs.language"
          :error="errors.language"
          @validate="(event) => validators.autocomplete(event, inputOptions.orgType)"
        />
        <DatePicker
          name="birthdate"
          :label="$t('form.birthdate')"
          required="required"
          v-model="inputs.birthdate"
          :error="errors.birthdate"
          @validate="(event) => validators.required(event)"
          :language="language"
        />

        <InputCheckbox
          v-model="inputs.ackLegalDataSecurityPers"
          name="ackLegalDataSecurityPers"
          :error="errors.ackLegalDataSecurityPers"
          @validate="(event) => validators.checked(event)"
        >
          <template v-slot:label>
            <i18n-t keypath="furtherInformation.person.ackLegal" tag="p" scope="global">
              <template v-slot:privacyPolicy>
                <a class="text-primary" target="_blank" rel="noreferrer noopener nofollow" :href="$t('furtherInformation.person.privacyPolicyLink')">{{ $t("furtherInformation.person.privacyPolicy") }}</a>
              </template>
            </i18n-t>
          </template>
        </InputCheckbox>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-6">
        <InfoBox
          :infoBodyText1="$t('furtherInformation.person.mpaEpdGlnHint')"
          :glnInfoUrl="$t('furtherInformation.glnInfoUrl')"
          :glnInfoUrlFr="$t('furtherInformation.glnInfoUrlFr')"
          :orderLink="$t('furtherInformation.infoTextFindGln')"
          :orderLinkFr="$t('furtherInformation.infoTextFindGlnFr')"
          :showGlnInfo="true"
          :language="language"
        />

      </div>
      </div>
  </form>
</template>

<style scoped>
.max-width {
  max-width: 500px;
}
</style>
