<template>
  <div>
    <div class="hreg--card-info">
      <card-header :title="$t('membersForm.memTitle')" />
      <card-body class="row">
        <ul class="list-unstyled ">
          <li>
            <i18n-t keypath="membersForm.memBodyText" scope="global">
            <template v-slot:memTitle>
              <a tabindex="0" class="text-primary" target="_blank" rel="noreferrer noopener nofollow" :href="$t('membersForm.memExplainLink')">{{ $t("membersForm.memTitle") }}</a>
            </template>
          </i18n-t>
          </li>
          <br />
          <li>{{ $t("membersForm.memListText.header") }}</li>
          <br />
          <ul class="d--no-indent">
            <p class="d--mvb-1"> <i class="mdi mdi-check-circle"></i> {{ $t("membersForm.memListText.internetAccess") }}</p>
            <p class="d--mvb-1"> <i class="mdi mdi-check-circle"></i> {{ $t("membersForm.memListText.mobilePhone") }}</p>
            
            <p class="d--mvb-1"> <i class="mdi mdi-check-circle"></i> {{ $t("membersForm.memListText.validId") }} 
              <Popper placement="top" arrow>
                <i class="mdi mdi-information-outline pointer"></i>
                <template #content>
                  <h6>{{ $t("membersForm.memListText.validDocs") }}</h6>
                  <p class="d--mvb-1"> <i class="mdi mdi-check-circle"></i> {{ $t("membersForm.memListText.idSwiss") }}</p>
                  <p class="d--mvb-1"> <i class="mdi mdi-check-circle"></i> {{ $t("membersForm.memListText.idForeign") }}</p>
                </template>
              </Popper>
            </p>
            <p class="d--mvb-1"> <i class="mdi mdi-check-circle"></i> {{ $t("membersForm.memListText.mobilePhoneAccess") }}</p>
            <p class="d--mvb-1"> <i class="mdi mdi-check-circle"></i> {{ $t("membersForm.memListText.glnNumber") }}</p>
          </ul>
        </ul>
      </card-body>
    </div>

    <div class="hreg--card-info">
      <card-header
        :title="$t('membersForm.memAssociation.header')"
        class="row"
      />
      <card-body class="row">
        <p>
          <i18n-t keypath="membersForm.memAssociation.specialCondition" scope="global">
            <template v-slot:overviewLink>
              <a class="text-primary" target="_blank" rel="noreferrer noopener nofollow" :href="$t('membersForm.memPricingLink')">{{ $t("membersForm.memAssociation.overviewLink") }}</a>
            </template>
          </i18n-t>
        </p>
      </card-body>

      <card-footer title="" class="row">
        <Autocomplete
          name="associations"
          noValue="membersForm.selectionNoAssociation"
          :label="$t('membersForm.memFooterTitle')"
          :error="contractDetailStore.errors.association"
          :suggestions="inputOptionStore.inputOptions.associations"
          :language="appStore.currentLanguage.toLowerCase()"
          v-model="membersStore.associationSelection"
          @done="(selection, suggestions) => changeAssociation(selection)"
        />
        <div
          class="custom-checkbox"
          v-if="offerStore.showRetiredCheckbox"
        >
          <InputCheckbox
            v-model="offerStore.retiredCheckbox"
            name="cbOffer"
            :label="$t('offerForm.retired')"
            @validate="(event) => handleRetiredChange(event)"
          />
        </div>
      </card-footer>
    </div>
    <hr class="d--divider-h" />
    <button-box
      :disableBackBtn="true"
      :routeBack="routeHome"
      :routeForward="routeIdentity"
      :forwardBtnText="$t('form.button.forward')"
      class="col"
      :disableBtn="forwardDisabled"
    />
    <Modal
      v-show="modalOpen"
      :header="$t('membersForm.modalMemberTitle', {association: associationName})"
      :bodyComponent="ModalBody"
      :bodyComponentProps="{association: associationName, url: associationUrl}"
      @close="modalOpen=false"
    />
  </div>
</template>

<script setup>
import { ref, watch }         from  "vue";
import Autocomplete           from "@/components/form/Autocomplete.vue";
import CardBody               from "@/components/CardBody.vue";
import CardFooter             from "@/components/CardFooter.vue";
import CardHeader             from "@/components/CardHeader.vue";
import ButtonBox              from "@/components/ButtonBox.vue";
import Modal                  from "@/components/Modal.vue";
import ModalBody from "@/components/membership/ModalBody.vue";
import InputCheckbox          from "@/components/form/InputCheckbox.vue";

import { useMembersStore }        from "@/store/MembersStore";
import { useAppStore }            from "@/store/AppStore";
import { useInputOptionStore }    from "@/store/InputOptionStore";
import { useContractDetailStore } from "@/store/ContractDetailStore";
import { useOfferStore } from "@/store/OfferStore";

const membersStore        = useMembersStore();
const appStore            = useAppStore();
const inputOptionStore    = useInputOptionStore();
const contractDetailStore = useContractDetailStore();
const offerStore          = useOfferStore();

appStore.activeStep = appStore.screenNumbers.home;

// Variables + Values
const options = inputOptionStore.inputOptions;

// Text + String
const routeHome = appStore.routeHome;
const routeIdentity = appStore.routeIdentity;

const forwardDisabled = ref(false);
const modalOpen       = ref(false);
const associationName = ref("");
const associationUrl  = ref("");

function changeAssociation(selected) {
  const lang = appStore.currentLanguage.toLowerCase();
  const assocFull = inputOptionStore.inputOptions.associations[lang].filter(
    item => item.key === selected)[0];
  if (assocFull?.registrationLink?.de) {
    associationName.value = assocFull[lang];
    associationUrl.value  = assocFull.registrationLink[lang] || assocFull.registrationLink.de;
    forwardDisabled.value = true;
    modalOpen.value = true;
  }
  else {
    forwardDisabled.value = false;
  }
}

function handleRetiredChange(event) {
  offerStore.retiredCheckbox = event.target.value;
}


// Wait until `inputOptionStore` is ready and trigger
// conditional opening of modal. Just in case someone sets `CURACASA`
// for example and reloads the page "to get rid of the modal".
watch(() =>inputOptionStore.inputOptions.associations, (n, o) => {
  changeAssociation(membersStore.associationSelection);
});
</script>

<style scoped>
#membersDivider {
  height: auto;
  margin: 10px 1px 10px 1px;
}
#dropDownColor {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
}

.padding-left {
  padding-left: 15px;
}

.pointer{
  cursor: pointer;
}
</style>
