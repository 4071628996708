<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { i18nGlobal } from "@/i18n";
import ErrorSmall from "@/components/form/ErrorSmall.vue";
import LabelForInput from "@/components/form/LabelForInput.vue";
import RemoveJobButton from "@/components/further-information/RemoveJobButton.vue";

const props = defineProps({
  showPleaseSelect: {
    type: Boolean,
    default: false,
    required: false
  },
  hint: "",
  initialValue: {
    type: String,
    default: "",
    required: false
  },
  label: "",
  language: "",
  name: "",
  required: "",
  error: "",
  noValue: {
    type: String,
    default: "form.selectionPlease",
    required: false
  },
  suggestions: Object,
  showRemove: Boolean,
});

const emit = defineEmits(["validate", "done"]);
const autocompleteSuggestions = ref({});
const selected = ref("");
const selectedKey = defineModel();
const selectedIndex = ref(null);

const filterText = ref("");
const doFilter = ref(true);

const isOpen = ref(false);

const filtered = computed(() => {
  // If this runs before store is populated.
  if (!props?.suggestions[props.language]) return [];
  // Must not run filter without filterText, or toLowerCase fails.
  // Also, must not filter if user just clicked / tabbed into input.
  // The input text is then selected, and the whole list is shown.
  if (!filterText.value || !doFilter.value) return props.suggestions[props.language];
  return props.suggestions[props.language].filter(
    item => (item[props.language] || item.de).toLowerCase().includes(filterText.value.toLowerCase()));
});

onMounted(() => {
  if (!selectedKey.value && props.initialValue) {
    selectedKey.value = props.initialValue;
  }
});

function clearSelection() {
  filterText.value = i18nGlobal.t(props.noValue);
  selected.value = null;
  selectedKey.value = "";
  isOpen.value = false;
}

// Support for keyboard navigation.
// Up / down arrow keys for moving inside (filtered) suggestion list.
// In the template, there are handlers for left / right keys that turn on filtering,
// and for enter, which selects the highlighted element.
function moveSelectedIndex(increment) {
  if (selectedIndex.value === null) {
    selectedIndex.value = 0;
  }
  else {
    selectedIndex.value = selectedIndex.value  + increment;
    if (selectedIndex.value < 0) {
      selectedIndex.value = filtered.value.length-1;
    }
    else if (selectedIndex.value > filtered.value.length-1) {
      selectedIndex.value = 0;
    }
  }
  const selectedElement = autocompleteSuggestions.value[selectedIndex.value];
  if (selectedElement) {
    selectedElement.scrollIntoView({ block: 'nearest' });
  }
}

function setSelected(result) {
  if (!result) {
    setFilterText();
    return;
  }
  selected.value = result;
  selectedKey.value = result.key;
  filterText.value = result[props.language] || result.de;
  isOpen.value = false;
}

function setFilterText(result) {
  const found = selectedKey.value
    ? props?.suggestions[props.language]?.find(
      item => item.key === selectedKey?.value)
    : null;
  if(props.initialValue) {
    filterText.value = found?.[props.language] || found?.de  || i18nGlobal.t(props.initialValue);
  }
  else {
    filterText.value = found?.[props.language] || found?.de  || i18nGlobal.t(props.noValue);
  }
}
// If suggestions are ready at initialization, immediately set..
setFilterText();

// ..if they are not (if they come over the network, for example),
// try again after they change.
watch(
  () => props.suggestions,
  (newSuggestions, oldSuggestions) => {
    setFilterText();
  }
);

// If language changes, translate the text, but only if an item
// was already selected.
// (We cannot translate substrings, only stuff that exists in props.suggestions).
watch(() => props.language, (newLanguage, oldLanguage) => {
  if (selected.value) {
    filterText.value = selected.value[newLanguage] || selected.value.de;
  }
  // If language changes before "selected" was ever set, translate initial value.
  else if (selectedKey.value) {
    const filtered = props.suggestions.de.filter(
      (item) => item.key === selectedKey.value)?.[0];
    filterText.value = filtered?.[props.language] || filtered?.de;
  }
  else {
    filterText.value = i18nGlobal.t(props.noValue);
  }
});

// The data binding is to "selected" (which is a key like "42"),
// but we show "filterText", which is a translated text like "forty two".
watch(selectedKey, (newSelected, oldSelected) => {
  setFilterText("");
});

function onFocus(event) {
  event.target.select();
  doFilter.value = false;
  isOpen.value = true;
}

</script>

<template>
  <div :class="['form-group', required && 'required']">
    <LabelForInput :name :hint :label v-if="label"/>
    <!-- Must clear "selected" because it cannot be translated unless is
         a value in "suggestions" -->
    <RemoveJobButton
      v-if="showRemove === true"
      @click="
      $emit('close')
      "
      />
    <input
      type="text"
      :name
      :id="name"
      :required
      :class="['custom-select', error && 'error-border']"
      v-model="filterText"
      @blur="
        // Note, if user clicks on item in suggestions, that gets handled, first.
        // (mousedown happens before blur. click on the item does not run at all if
        // we have a blur here, because list is closed before it can handle the click.
        $emit('validate', { target: { name, value: selectedKey } }); isOpen = false;
        setSelected()"
      @focus="onFocus"
      @input="moveSelectedIndex(0); doFilter=true;"

      @keydown.down="moveSelectedIndex(1)"
      @keydown.up="moveSelectedIndex(-1)"
      @keydown.enter.prevent="setSelected(filtered[selectedIndex])"
      @keydown.left="doFilter = true"
      @keydown.right="doFilter = true"
      Autocomplete="off"
    />
    <ul
      tabindex="-1"
      :id="name"
      v-show="isOpen"
      class="autocomplete-search-suggestions max-width"
    >
      <li
        v-show="!filterText || selected && showPleaseSelect"
        class="autocomplete-search-item max-width"
        @mousedown="clearSelection(); $emit('done', '')"
      >
        {{ $t(noValue) }}
      </li>
      <!-- Changed @click to @mousedown to avoid problems -->
      <!-- With eventhandler execution order -->
      <li
        :class="['autocomplete-search-item', 'max-width',
          index === selectedIndex && 'autocomplete-selected']"
        :id="suggestion.key"
        v-for="(suggestion, index) in filtered"
        :key="index"
        :ref="(el) => autocompleteSuggestions[index] = el"
        @mousedown="
          setSelected(suggestion);
          $emit('done', suggestion.key, suggestions[language].map(item => item.key));
          $emit('validate', { target: { name, value: selected?.key } }); isOpen = false;"
      >
        {{ suggestion[language] || suggestion.de }}
      </li>
    </ul>
    <ErrorSmall :name :error :label/>
  </div>
</template>

<style scoped>
.max-width {
  max-width: 500px;
}
.autocomplete-search-suggestions {
  position: absolute;
  z-index: 999;
  padding: 0;
  margin: 0;
  border: 1px solid #999999;
  background-color: white;
  max-height: 200px;
  overflow-y: auto;
}

.autocomplete-search-item {
  cursor: pointer;
  padding: 6px 16px;
}

.autocomplete-selected {
  background-color: #ffefe6;
}

.rowContainer {
  display: flex;
  align-items: center
}

.error-border {
  border-color: #F05347;
}
</style>
