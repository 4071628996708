<script setup>
import { ref } from 'vue';
import ErrorSmall    from "@/components/form/ErrorSmall.vue";
import LabelForInput from "@/components/form/LabelForInput.vue";
import DeleteInputIcon from "@/components/form/DeleteInputIcon.vue";
const props = defineProps({
  hint: "",
  label: "",
  name: "",
  required: "",
  error: "",
  readonly: "",
});

const emits = defineEmits(['validate']);
const value = defineModel();

const inputRef = ref();

// So parents can focus our "<input>"..
function focusInput() {
  if (inputRef.value) {
    inputRef.value.focus();
  }
}
// ..make function available to parent.
defineExpose({
  focusInput
});

</script>

<template>
  <div :class="['form-group', required && 'required']">
    <LabelForInput :name :hint :label />
    <div>
      <div class="input-container">
        <input
          data-test="inputText"
          ref="inputRef"
          type="text"
          :name
          :id="name"
          :readonly
          :required
          :class="['form-control', error && 'error-border']"
          v-model="value"
          @blur="$emit('validate', $event)"
          pattern="/[^a-zA-Z0-9\s]/"
        />
        <DeleteInputIcon v-if="error" @clearInput="value = ''" />
      </div>
    </div>
    <ErrorSmall :name :error :label/>
  </div>
</template>
<style scoped>
.max-width {
  max-width: 500px;
}
</style>
