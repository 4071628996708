import { defineStore } from "pinia";

export const useMembersStore = defineStore("MembersStore", {
  state: () => {
    return {
      associationsWithPraxisSoftware: {
        // assoc  e-mail domain with praxis software
        ALOSI:     "hin.email",
        ARLD:      "hin.email",
        ASPI:      "hin.physio",
        DLV:       "hin.email",
        EVS:       "hin.email",
        PHYSIO:    "hin.physio",
        PP:        "hin.physio",
        "SVO-FSO": "hin.email",
      },
      associationSelection: "",
      defaultAssociation: { key: "no-association", value: undefined },
      timer: 1000 * 60 * 60 * 24, // 24 hours
      outOfScopeAssociationKeys: ["FSP", "CHIRO"],
    };
  },
  getters: {
    hasPraxisSoftware: (state) => {
      return state.associationsWithPraxisSoftware[state.associationSelection];
    },
    hasAssociation: (state) => {
      return state.associationSelection !== "" && state.associationSelection !== state.defaultAssociation.key;
    },
  },
  persist: {
    storage: sessionStorage,
  }
});
