<script setup>
import ErrorSmall    from "@/components/form/ErrorSmall.vue";
import LabelForInput from "@/components/form/LabelForInput.vue";
import DeleteInputIcon from "@/components/form/DeleteInputIcon.vue";
import { defineModel } from "vue";

const valueCity = defineModel('valueCity');
const valueZipCode = defineModel('valueZipCode');

defineProps({
  hintCity: "",
  hintZipCode: "",
  labelCity: "",
  labelZipCode: "",
  nameCity: "",
  nameZipCode: "",
  required: "",
  valueCity: "",
  valueZipCode: "",
  minLengthCity: 2,
  maxLengthCity: 20,
  minLengthZipCode: 4,
  maxLengthZipCode: 10,
  errorCity: "",
  errorZipCode: "",
})

const emits = defineEmits(['validateZipCode', 'validateCity', 'update:valueZipCode', 'update:valueCity'])
</script>

<template>
  <div :class="['form-group', 'form-row', required && 'required']">
    <div class="col-4">
      <LabelForInput :name="nameZipCode" :hint="hintZipCode" :label="labelZipCode" />
      <div>
        <div class="input-container">
          <input
            type="text"
            :class="['form-control','max-width', errorZipCode && 'error-border']"
            :id="nameZipCode"
            :name="nameZipCode"
            v-model="valueZipCode"
            :minlength="minLengthZipCode"
            :maxlength="maxLengthZipCode"
            :required
            @input="valueZipCode = $event.target.value"
            @blur="$emit('validateZipCode', $event)"
          />
          <DeleteInputIcon v-if="errorZipCode" @clearInput="valueZipCode = ''" />
        </div>
      </div>
      <ErrorSmall :name="nameZipCode" :error="errorZipCode" :label="labelZipCode"/>
    </div>

    <div class="col-8">
      <LabelForInput :name="nameCity" :hint="hintCity" :label="labelCity" />
      <div>
          <div class="input-container">
            <input
              type="text"
              :class="['form-control', errorCity && 'error-border']"
              :id="nameCity"
              :name="nameCity"
              :value="valueCity"
              :minlength="minLengthCity"
              :maxlength="maxLengthCity"
              :required
              @input="valueCity = $event.target.value"
              @blur="$emit('validateCity', $event)"
            />
            <DeleteInputIcon v-if="errorCity" @clearInput="valueCity = ''" />
          </div>
        </div>
      <ErrorSmall :name="nameCity" :error="errorCity" :label="labelCity"/>
    </div>
  </div>
</template>

<style scoped>
.max-width {
  max-width: 500px;
}
</style>